import { LoKationButton } from '@components/button/button'
import { Modal } from '@components/modal/modal'
import { ModalBody } from '@components/modal/modal-body'
import { ModalHeader } from '@components/modal/modal-header'
import { YouTubeEmbed } from '@components/youtube-embed/youtube-embed'
import { RootState } from '@redux/store'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { EndUserProps } from 'src/services/user/user.types'

import AlzDesktop from './Desktop.png'
import AlzMobile from './Mobile.png'

interface MortgageInsightModalProps {
	dismissModal: () => void
	currentUser: EndUserProps | null
}

function HomepageModalPrototype({ dismissModal, currentUser }: MortgageInsightModalProps) {
	const [isMobile, setIsMobile] = useState(false)
	const [isFlorida, setIsFlorida] = useState(false)

	const handleResize = () => {
		setIsMobile(window.innerWidth < 768)
	}

	useEffect(() => {
		if (!currentUser) {
			return
		}

		const isUserFloridaLicensed =
			currentUser.licenseInformation.some((license) => {
				return license.licensedState.licensedStateId === 1
			}) || false
		console.log(isUserFloridaLicensed)
		setIsFlorida(isUserFloridaLicensed)
	}, [currentUser])

	useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<>
			<Modal
				onClose={dismissModal}
				maxWidth={800}
				maxHeight={825}
				fixedHeight={false}
				className="primary overflow-y__scroll"
			>
				<ModalHeader title="Join Us in the Fight Against Alzheimer’s!" center={true}>
					<></>
				</ModalHeader>
				<ModalBody>
					<div>
						{!isFlorida ? (
							<>
								<div className="col-12 flex flex-wrap mb-20 flex-alignItems-center">
									<div className="col-12 mb-20 flex flex-justifyContent-center">
										<div className="col-12">
											<a
												href="https://act.alz.org/site/TR;jsessionid=00000000.app20108b?team_id=887601&fr_id=17532&pg=team&NONCE_TOKEN=A9E61AFBDDA1CC40756DEBF0FA3D523D"
												target="_blank"
												rel="noreferrer"
											>
												<img src={isMobile ? AlzMobile : AlzDesktop} width={'100%'} alt="NAR" />
											</a>
										</div>
									</div>

									<div className="col-12">
										<div
											className="col-12 mb-30 flex flex-justifyContent-center flex-column flex-alignItems-center"
											style={{ textAlign: 'center' }}
										>
											<h3 className="mb-20">LoKation Team!</h3>
											<div className="col-12 col-md-8">
												We invite you to stand with us in the fight against Alzheimer’s disease
												by supporting the 'Walk to End Alzheimer's.' This devastating condition
												affects millions of lives, and your involvement can offer hope and drive
												meaningful change.
											</div>
										</div>

										<div className="col-12 flex flex-wrap mb-10">
											<div
												className="col-12 col-md-6 pr-10 pr-0-md-down"
												style={{ textAlign: 'center' }}
											>
												<div className="mb-10">
													<h3>Donate</h3>
												</div>
												<div>
													If you can't attend, please consider donating. Every contribution
													helps! Our CEO, Nathan Klutznick, will match donations up to $5,000.
												</div>
												<LoKationButton
													className="mt-10 mb-20"
													variant="outlined"
													label="Donate"
													size={'sm'}
													onClick={() => {
														window.open(
															`https://act.alz.org/site/Donation2?idb=998422278&df_id=56179&FR_ID=17532&mfc_pref=T&PROXY_ID=887601&56179.donation=form1&PROXY_TYPE=22`,
															'_blank',
														)
													}}
												/>
											</div>
											<div
												className="col-12 col-md-6 pl-10 pl-0-md-down"
												style={{ textAlign: 'center' }}
											>
												<div className="mb-10">
													<h3>Spread the Word</h3>
												</div>
												<div>
													Share this cause with your network to raise awareness and inspire
													others to support the fight against Alzheimer’s.
												</div>
												<LoKationButton
													className="mt-10 mb-20"
													variant="outlined"
													label="Learn More"
													size={'sm'}
													onClick={() => {
														window.open(
															`https://act.alz.org/site/TR;jsessionid=00000000.app20108b?team_id=887601&fr_id=17532&pg=team&NONCE_TOKEN=A9E61AFBDDA1CC40756DEBF0FA3D523D`,
															'_blank',
														)
													}}
												/>
											</div>
										</div>

										<div className="my-30" style={{ textAlign: 'center' }}>
											<h6>
												Your generosity brings us closer to a world free of Alzheimer’s. Thank
												you!
											</h6>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<div className="col-12 flex flex-wrap mb-20 flex-alignItems-center">
									<div className="col-12 mb-20 flex flex-justifyContent-center">
										<div className="col-12 col-md-8">
											<YouTubeEmbed videoUrl={'https://www.youtube.com/watch?v=w7Ryzc17sBM'} />
										</div>
									</div>

									<div className="col-12">
										<div
											className="col-12 mb-30 flex flex-justifyContent-center"
											style={{ textAlign: 'center' }}
										>
											<div className="col-12 col-md-8">
												Be part of our 'Walk to End Alzheimer's' on Sunday, November 10, 2024.
												Your support can make a big difference!
											</div>
										</div>

										<div className="col-12 flex flex-wrap mb-10">
											<div className="col-12 col-md-6 pr-10 pr-0-md-down">
												<div className="mb-10" style={{ textAlign: 'center' }}>
													<h3>Walk With Us</h3>
												</div>
												<div>
													<strong>Time</strong>: Meetup 8:30 AM - 8:45 AM / Walk starts at
													9:00 AM
												</div>
												<div>
													<strong>Location</strong>: South County Regional Park
												</div>
												<div>
													<strong>Address</strong>: 20405 Amphitheater Circle, Boca Raton, FL
													33498
												</div>
												<div style={{ textAlign: 'center' }}>
													<LoKationButton
														className="mt-10 mb-20"
														variant="outlined"
														label="Register"
														size={'sm'}
														onClick={() => {
															window.open(
																`https://act.alz.org/site/TR;jsessionid=00000000.app20108b?team_id=887601&fr_id=17532&pg=team&NONCE_TOKEN=A9E61AFBDDA1CC40756DEBF0FA3D523D`,
																'_blank',
															)
														}}
													/>
												</div>
											</div>
											<div className="col-12 col-md-6 pl-10 pl-0-md-down">
												<div className="mb-10" style={{ textAlign: 'center' }}>
													<h3>Donate Only</h3>
												</div>
												<div>
													You can register on{' '}
													<a
														href="https://act.alz.org/site/TR;jsessionid=00000000.app20108b?team_id=887601&fr_id=17532&pg=team&NONCE_TOKEN=A9E61AFBDDA1CC40756DEBF0FA3D523D"
														target="_blank"
														rel="noreferrer"
													>
														our team page{' '}
													</a>
													or donate to{' '}
													<a
														href="https://act.alz.org/site/Donation2?idb=998422278&df_id=56179&FR_ID=17532&mfc_pref=T&PROXY_ID=887601&56179.donation=form1&PROXY_TYPE=22"
														target="_blank"
														rel="noreferrer"
													>
														our fundraising page
													</a>
													. Every dollar counts, and CEO Nathan Klutznick will match donations
													up to $5,000!
												</div>
												<div style={{ textAlign: 'center' }}>
													<LoKationButton
														className="mt-10 mb-20"
														variant="outlined"
														label="Donate"
														size={'sm'}
														onClick={() => {
															window.open(
																`https://act.alz.org/site/Donation2?idb=998422278&df_id=56179&FR_ID=17532&mfc_pref=T&PROXY_ID=887601&56179.donation=form1&PROXY_TYPE=22`,
																'_blank',
															)
														}}
													/>
												</div>
											</div>
										</div>

										<div className="my-30" style={{ textAlign: 'center' }}>
											<h6>
												Your participation helps raise awareness and support. We look forward to
												seeing you there!
											</h6>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		currentUser: state.user,
	}
}

export const HomepageModal = connect(mapStateToProps)(HomepageModalPrototype)
