import { GenericContentLoader } from '@components/generic-content-loader/generic-content-loader'
import { ResourceCardList } from '@components/resource-card-list/resource-card-list'
import { useEffect, useState } from 'react'

import { DOMAIN_ID } from '../../../../services/domain/domain.api'
import { LandingPageAPI } from '../../../../services/landing-page/landing-page.api'
import { ResourcesAPI } from '../../../../services/resources/resources.api'
import { LokationResource } from '../../../../services/resources/resources.types'

export interface FeaturedResourcesProps {
	showHiddenResources?: boolean
}

export function FeaturedResources(props: FeaturedResourcesProps) {
	const [primaryResourcesToShow, setPrimaryResourcesToShow] = useState<LokationResource[] | null>(null)

	useEffect(() => {
		LandingPageAPI.getResources(DOMAIN_ID).then((res) => {
			if (res.data.items.length > 0) {
				const sortedResults = res.data.items.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))

				setPrimaryResourcesToShow(sortedResults.map((resourceRef) => resourceRef.resource))
			} else {
				ResourcesAPI.getResources({ page: 0, size: 6 }).then((res) => {
					setPrimaryResourcesToShow(res.data.items)
				})
			}
		})
	}, [])

	return (
		<>
			{!primaryResourcesToShow && (
				<>
					<GenericContentLoader width={300} height={100} className="mr-10 mb-10" />
					<GenericContentLoader width={300} height={100} className="mr-10 mb-10" />
					<GenericContentLoader width={300} height={100} className="mr-10 mb-10" />
					<GenericContentLoader width={300} height={100} className="mr-10 mb-10" />
				</>
			)}
			{primaryResourcesToShow && (
				<ResourceCardList
					resources={primaryResourcesToShow}
					cardStyle={'WIDE_CARD'}
					groupByTag={false}
					includeStyleToggle={false}
					showHiddenResources={props.showHiddenResources}
				/>
			)}
		</>
	)
}
