import { ModalTypes } from './modal.types'

export function ModalHeader(props: ModalTypes.Header) {
	return (
		<div
			className={`flex flex-alignItems-center px-20 pt-20 pb-10 bg-color__adjust-0 ${props.center ? 'flex flex-justifyContent-center' : ''}`}
		>
			<h3 className="mr-30">{props.title}</h3>
			{props.children}
		</div>
	)
}
