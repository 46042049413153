import { LoKationButton } from '@components/button/button'
import { Chip } from '@components/chip/chip'
import { RichTextEditor } from '@components/rich-text-editor/rich-text-editor'
import { SideSheet } from '@components/side-sheet/side-sheet'
import { SideSheetTypes } from '@components/side-sheet/side-sheet.types'
import { TextInput } from '@components/text-input/text-input'
import { ToggleButton, ToggleButtonOption } from '@components/toggle-button/toggle-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RootState } from '@redux/store'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { LicensedState } from 'src/services/licensed-states/licenses-states.types'
import { LicensedRegionsService } from 'src/services/regions/regions.service'
import { LicensedRegion } from 'src/services/regions/regions.types'
import { LokationSpecialty } from 'src/services/specialties/specialty.types'

import { GenericDeleteConfirmationModal } from '../../../../../modals/generic-delete-confirmation/generic-delete-confirmation'
import { UserSelectorModal } from '../../../../../modals/user-selector/user-selector'
import { LicensedStatesService } from '../../../../../services/licensed-states/licensed-states.service'
import { MessageDraftsAPI } from '../../../../../services/messaging-service/message-drafts.api'
import { MessageDraftTypes } from '../../../../../services/messaging-service/message-drafts.types'
import { SpecialtyService } from '../../../../../services/specialties/specialty.service'
import { UserAPI } from '../../../../../services/user/user.api'
import { EndUserProps } from '../../../../../services/user/user.types'
import { useMessagesAdminDispatch } from '../../state/messages-admin__state'

interface ComposeMessageSideSheetProps {
	message: MessageDraftTypes.MessageDraft
	onClose: () => void
	onSendDraft: (draftId: number) => void
	disableFields: boolean
}

interface ChangeDTO {
	key: keyof MessageDraftTypes.MessageDraft
	value: unknown
}

type ConnectedProps = {
	licensedStates: LicensedState[]
	specialties: LokationSpecialty[]
	licensedRegions: LicensedRegion[]
}

function ComposeMessageSideSheetPrototype(props: ComposeMessageSideSheetProps & ConnectedProps) {
	/** ======================================= */
	/** Props and State */
	const [modifiedMessage, setModifiedMessage] = useState(props.message)
	const [recipientEndUserDetails, setRecipientEndUserDetails] = useState<EndUserProps[]>([])
	const [showSelectUsersModal, setShowSelectUsersModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const messagesDispatch = useMessagesAdminDispatch()
	const [charCount, setCharCount] = useState(props.message.message?.length ?? 0)

	/** ======================================= */
	/** Effects */

	/** Search for details about recipients of this message */
	useEffect(() => {
		const getUserPromises: Promise<EndUserProps>[] = []

		modifiedMessage.endUserRecipients.forEach((endUserId) => {
			const getUserPromise = new Promise<EndUserProps>((resolve, reject) => {
				UserAPI.getUserDetails(endUserId).then((res) => {
					resolve(res.data)
				})
			})
			getUserPromises.push(getUserPromise)
		})

		Promise.all(getUserPromises).then((res) => {
			setRecipientEndUserDetails(res)
		})
	}, [modifiedMessage.endUserRecipients])

	/** ======================================= */
	/** Methods */

	function updateMessage(changes: ChangeDTO[]): void {
		let updatedMessage = { ...modifiedMessage }
		changes.forEach((change) => {
			updatedMessage = { ...updatedMessage, [change.key]: change.value }
		})
		setModifiedMessage(updatedMessage)
	}

	function saveDraftForLater(): void {
		if (modifiedMessage.chatMessageDraftId < 0) {
			MessageDraftsAPI()
				.createDraft(modifiedMessage)
				.then((res) => {
					messagesDispatch({ type: 'create-message', payload: res.data })
					props.onClose()
				})
		} else {
			MessageDraftsAPI()
				.updateDraft(modifiedMessage)
				.then((res) => {
					messagesDispatch({ type: 'update-message', payload: res.data })
					props.onClose()
				})
		}
	}

	function sendDraft(): void {
		let saveDraftPromises: Promise<AxiosResponse<MessageDraftTypes.MessageDraft, any>>

		if (modifiedMessage.chatMessageDraftId < 0) {
			saveDraftPromises = MessageDraftsAPI().createDraft(modifiedMessage)
		} else {
			saveDraftPromises = MessageDraftsAPI().updateDraft(modifiedMessage)
		}

		saveDraftPromises.then((res) => {
			console.log('Draft Promise: ', res)
			const draftIdToSend = res.data.chatMessageDraftId
			MessageDraftsAPI()
				.sendDraft(draftIdToSend)
				.then((res) => {
					props.onClose()
					props.onSendDraft(draftIdToSend)
				})
		})
	}

	function renderFooter(): React.ReactNode {
		return (
			<div className="flex flex-justifyContent-end">
				<LoKationButton
					variant="text"
					label="Save for Later"
					size="sm"
					className="mr-10"
					onClick={() => {
						saveDraftForLater()
					}}
					disabled={props.disableFields}
				/>
				<LoKationButton
					variant="outlined"
					label="Send"
					size="sm"
					onClick={() => {
						sendDraft()
					}}
					disabled={props.disableFields}
				/>
			</div>
		)
	}

	function getActions(): SideSheetTypes.Action[] {
		const actions: SideSheetTypes.Action[] = []

		if (!props.disableFields) {
			actions.push({
				label: 'Delete',
				icon: 'trash',
				onClick: () => {
					setShowDeleteModal(true)
				},
			})
		}

		return actions
	}

	function handleRemoveRecipient(recipientType: 'SPECIALTY' | 'STATE' | 'REGION' | 'USER', idToRemove: number) {
		setModifiedMessage((currentMessage) => {
			const update = { ...currentMessage }
			switch (recipientType) {
				case 'SPECIALTY':
					update.specialtyRecipients = update.specialtyRecipients.filter((id) => id !== idToRemove)
					break
				case 'STATE':
					update.licensedStateRecipients = update.licensedStateRecipients.filter((id) => id !== idToRemove)
					break
				case 'REGION':
					update.regionRecipients = update.regionRecipients.filter((id) => id !== idToRemove)
					break
				case 'USER':
					update.endUserRecipients = update.endUserRecipients.filter((id) => id !== idToRemove)
					break
			}
			return update
		})
	}

	function getChipsForRecipients(): React.ReactNode {
		return (
			<>
				{modifiedMessage.specialtyRecipients.map((specialtyId) => {
					const specialtyObj = SpecialtyService.getSpecialtyById(props.specialties, specialtyId)
					return (
						<Chip
							label={specialtyObj ? specialtyObj.specialtyName : 'Unknown'}
							color={'var(--colorAdjust60)'}
							className="mr-10 my-5"
							onClickRemove={() => {
								if (specialtyObj) {
									handleRemoveRecipient('SPECIALTY', specialtyObj.specialtyId)
								}
							}}
						/>
					)
				})}

				{modifiedMessage.licensedStateRecipients.map((licensedStateId) => {
					const stateObj = LicensedStatesService.getStateFromId(props.licensedStates, licensedStateId)
					return (
						<Chip
							label={stateObj ? stateObj.stateName : 'Unknown'}
							color={'var(--colorAdjust60)'}
							className="mr-10 my-5"
							onClickRemove={(event) => {
								if (stateObj) {
									handleRemoveRecipient('STATE', stateObj.licensedStateId)
								}
							}}
						/>
					)
				})}

				{modifiedMessage.regionRecipients.map((regionId) => {
					const regionObj = LicensedRegionsService.getRegionFromId(regionId)
					return (
						<Chip
							label={regionObj ? regionObj.regionName : 'Unknown'}
							color={'var(--colorAdjust60)'}
							className="mr-10 my-5"
							onClickRemove={(event) => {
								if (regionObj) {
									handleRemoveRecipient('REGION', regionObj.regionId)
								}
							}}
						/>
					)
				})}

				{modifiedMessage.endUserRecipients.map((endUserId) => {
					const userObj = recipientEndUserDetails.find((endUser) => endUser.endUserId === endUserId)
					return (
						<Chip
							label={userObj ? `${userObj.firstName} ${userObj.lastName}` : 'Unknown'}
							color={'var(--colorAdjust60)'}
							className="mr-10 my-5"
							onClickRemove={() => {
								if (userObj) {
									handleRemoveRecipient('USER', userObj.endUserId)
								}
							}}
						/>
					)
				})}
			</>
		)
	}

	function getCardStyleOptions(): ToggleButtonOption<MessageDraftTypes.MessageType>[] {
		return [
			{
				value: 'EMAIL',
				label: <FontAwesomeIcon icon={['far', 'envelope']} className="color__accent" />,
			},
			{
				value: 'TEXT',
				label: <FontAwesomeIcon icon={['far', 'message']} className="color__accent" />,
			},
		]
	}

	/** ======================================= */
	/** Render Component */

	return (
		<>
			<SideSheet
				title="Compose Message"
				actions={getActions()}
				onClose={props.onClose}
				persistent={false}
				width="wide"
				footer={renderFooter()}
			>
				<div>
					<strong className="pb-5">Select Message Recipients</strong>
					<div
						className={`text-input flex flex-alignItems-center px-10 ${props.disableFields ? 'disabled' : ''}`}
						style={{ cursor: 'pointer' }}
						onClick={(event) => {
							setShowSelectUsersModal(true)
						}}
					>
						<div className="flex-fillSpace flex flex-wrap">{getChipsForRecipients()}</div>
						<FontAwesomeIcon icon={['far', 'user']} />
					</div>
				</div>

				<div className="my-20">
					<strong>Message Type</strong>
					<ToggleButton
						options={getCardStyleOptions()}
						selectedOption={modifiedMessage.messageType}
						onSelect={(newState) => {
							setCharCount(0)
							updateMessage([{ key: 'messageType', value: newState }])
						}}
					/>
				</div>

				{modifiedMessage.messageType === 'EMAIL' ? (
					<RichTextEditor
						label={'Message'}
						className="mb-20"
						value={props.message.message}
						rows={10}
						onChange={(updatedValue) => {
							updateMessage([{ key: 'message', value: updatedValue }])
						}}
						disabled={props.disableFields}
					/>
				) : (
					<>
						<div className="col-xs-12 mt-20">
							<strong className="pb-5">Text Message</strong>
							<TextInput
								dataType="text"
								width="100%"
								rows={4}
								value={props.message.message}
								onChange={(updatedValue) => {
									let adjustedCharCount = updatedValue.length
									const newLineCount = (updatedValue.match(/\n/g) || []).length
									adjustedCharCount += newLineCount
									setCharCount(adjustedCharCount)
									updateMessage([{ key: 'message', value: updatedValue }])
								}}
								maxLength={160}
							/>
							<div className="flex flex-justifyContent-end">{charCount}/160</div>
						</div>
					</>
				)}
			</SideSheet>
			{showDeleteModal && (
				<GenericDeleteConfirmationModal
					itemLabel={`message draft`}
					onDelete={() => {
						MessageDraftsAPI()
							.deleteDraft({ messageDraftId: modifiedMessage.chatMessageDraftId })
							.then(() => {
								messagesDispatch({
									type: 'delete-message',
									payload: modifiedMessage.chatMessageDraftId,
								})
							})
						props.onClose()
					}}
					zIndex={10000}
					onClose={() => {
						setShowDeleteModal(false)
					}}
				/>
			)}
			{showSelectUsersModal && (
				<UserSelectorModal
					selectedRecipients={{
						endUsers: modifiedMessage.endUserRecipients,
						licensedStates: modifiedMessage.licensedStateRecipients,
						specialties: modifiedMessage.specialtyRecipients,
						licensedRegions: modifiedMessage.regionRecipients,
					}}
					zIndex={10000}
					onHide={() => {
						setShowSelectUsersModal(false)
					}}
					onSelectUsers={(updatedRecipients) => {
						updateMessage([
							{ key: 'endUserRecipients', value: updatedRecipients.endUsers },
							{ key: 'licensedStateRecipients', value: updatedRecipients.licensedStates },
							{ key: 'specialtyRecipients', value: updatedRecipients.specialties },
							{ key: 'regionRecipients', value: updatedRecipients.licensedRegions },
						])
						setShowSelectUsersModal(false)
					}}
				/>
			)}
		</>
	)
}

function mapStateToProps(state: RootState) {
	return {
		licensedStates: state.licensedStates,
		specialties: state.specialties,
		licensedRegions: state.licensedRegions,
	}
}

export const ComposeMessageSideSheet = connect(mapStateToProps)(ComposeMessageSideSheetPrototype)
