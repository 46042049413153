import { Avatar } from '@components/avatar/avatar'
import { LoKationButton } from '@components/button/button'
import { Checkbox } from '@components/checkbox/checkbox'
import { Dropdown } from '@components/dropdown/dropdown'
import { DropdownOptionProps } from '@components/dropdown/dropdown.types'
import { ImageUploadTile } from '@components/image-upload-tile/image-upload-tile'
import { Paper } from '@components/paper/paper'
import { PhoneInput } from '@components/text-input/phone-input'
import { TextInput } from '@components/text-input/text-input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { UserProfileModal } from 'src/modals/user-profile/user-profile'
import { RequestBaseURL } from 'src/services/axios/http-common.service'
import { CopyToClipboard } from 'src/services/copy-to-clipboard/copy-to-clipboard'
import { ReferralAPI } from 'src/services/referrals/referral.api'
import { EndUserReferral } from 'src/services/referrals/referral.types'

import { usStatesList } from '../../../../services/state-list'
import { UserAPI } from '../../../../services/user/user.api'
import { UserService } from '../../../../services/user/user.service'
import { EndUserLanguageSpecialty, EndUserProps, EndUserSpecialty } from '../../../../services/user/user.types'
import { useUserProfile, useUserProfileDispatch } from '../state/user-profile__state'
import { UserProfileService } from '../user-profile.service'

// import { Switch } from "react-router-dom";

interface PersonalInformationProps {
	userToEdit: EndUserProps
	currentUser: EndUserProps | null
}

function PersonalInformation(props: PersonalInformationProps) {
	const userProfileState = useUserProfile()
	const userProfileDispatch = useUserProfileDispatch()
	const [userPhoto, setUserPhoto] = useState<string | null>(null)
	const [availableSpecialties, setAvailableSpecialties] = useState<EndUserSpecialty[]>([])
	const [availableLanguageSpecialties, setAvailableLanguageSpecialties] = useState<EndUserLanguageSpecialty[]>([])
	const mergedUserProps = UserProfileService().applyModifiedStateToOriginalProps(
		userProfileState.modifiedProps,
		props.userToEdit,
	)

	const canUserEdit = props.currentUser ? UserService.isUserAdmin(props.currentUser) : false
	const [charCount, setCharCount] = useState(mergedUserProps.bio?.length ?? 0)
	const [referrals, setReferrals] = useState<EndUserReferral[] | undefined>(undefined)
	const [referralModal, setReferralModal] = useState<number | undefined>(undefined)
	const [imageUrls, setImageUrls] = useState<{ [key: number]: string | undefined }>({})

	useEffect(() => {
		if (referrals && referrals.length > 0) {
			referrals.forEach((referral) => {
				getReferralProfilePhoto(referral.endUserId).then((url) => {
					setImageUrls((prevUrls) => ({
						...prevUrls,
						[referral.endUserId]: url,
					}))
				})
			})
		}
	}, [referrals])

	useEffect(() => {
		if (props.userToEdit.hasProfilePicture) {
			UserAPI.getUserPhoto(props.userToEdit.endUserId).then((res) => {
				setUserPhoto(UserAPI.getUserPhotoURL(props.userToEdit.endUserId))
			})
		}
	}, [])

	useEffect(() => {
		UserAPI.getSpecialties().then((res) => {
			setAvailableSpecialties(res.data)
		})
		UserAPI.getLanguageSpecialties().then((res) => {
			const returnedLanguages = res.data
			const sortedLanguages = returnedLanguages.sort((a, b) => {
				if (a.languageName !== b.languageName) {
					return a.languageName > b.languageName ? 1 : -1
				}
				return a.languageName.localeCompare(b.languageName)
			})
			setAvailableLanguageSpecialties(sortedLanguages)
		})
		async function fetchUserReferrals() {
			try {
				const res = await ReferralAPI.getReferralsFromUser(props.userToEdit.endUserId)
				setReferrals(res.data)
			} catch (error) {
				console.error('Error fetching user referrals:', error)
			}
		}

		fetchUserReferrals()
	}, [])

	function getSelectedSpecialtyIds(): number[] {
		return mergedUserProps.specialties.map((specialty) => specialty.specialtyId)
	}

	function getSelectedLanguageSpecialtyIds(): number[] {
		return mergedUserProps.languageSpecialties.map((languageSpecialty) => languageSpecialty.languageSpecialtyId)
	}

	function getMailingStateOptions(): DropdownOptionProps<string>[] {
		return usStatesList.map((state) => {
			return {
				value: state.abbreviation,
				label: state.name,
			}
		})
	}

	function generateImageDownload(imageName: string) {
		// Assuming fetch is available
		fetch(`${RequestBaseURL}/api/v1/endUsers/${props.userToEdit.endUserId}/profilePicture`)
			.then((response) => response.blob())
			.then((blob) => {
				const url = window.URL.createObjectURL(blob)
				const link = document.createElement('a')
				link.href = url
				link.download = `${imageName}.png`
				document.body.appendChild(link)
				link.click()
				window.URL.revokeObjectURL(url)
				document.body.removeChild(link)
			})
			.catch((error) => console.error('Error downloading the image:', error))
	}

	const handleDismissModal = () => {
		setReferralModal(undefined)
	}

	async function getReferralProfilePhoto(endUserId: number): Promise<string | undefined> {
		const res = await UserAPI.getUserDetails(endUserId)
		if (res.data.hasProfilePicture) {
			return await UserAPI.getUserPhotoURL(endUserId)
		} else {
			return undefined
		}
	}

	return (
		<>
			<h2 className="mb-10">Personal Info</h2>

			<Paper
				bgColor="primary"
				padding={['all']}
				marginSize="section"
				margins={['bottom']}
				style={{ maxWidth: `800px` }}
			>
				<div className="flex flex-wrap mb-20 mt-10" style={{ maxWidth: `800px` }}>
					<TextInput
						width={300}
						dataType="text"
						label="First name"
						value={mergedUserProps.firstName}
						disabled={!canUserEdit}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'firstName',
									value: updatedValue,
								},
							})
						}}
						className={`mb-20 mr-20`}
					/>

					<TextInput
						width={300}
						dataType="text"
						label="Middle name"
						value={mergedUserProps.middleName}
						disabled={!canUserEdit}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'middleName',
									value: updatedValue,
								},
							})
						}}
						className={`mb-20 mr-20`}
					/>

					<TextInput
						width={300}
						dataType="text"
						label="Last name"
						value={mergedUserProps.lastName}
						disabled={!canUserEdit}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'lastName',
									value: updatedValue,
								},
							})
						}}
						className={`mb-20 mr-20`}
					/>

					<TextInput
						width={300}
						dataType="text"
						label="Business Name"
						disabled={!canUserEdit}
						value={mergedUserProps.businessName}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'businessName',
									value: updatedValue,
								},
							})
						}}
						className={`mb-20 mr-20`}
					/>

					<TextInput
						width={300}
						dataType="text"
						label="Email"
						disabled={!canUserEdit}
						value={mergedUserProps.email}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'email',
									value: updatedValue,
								},
							})
						}}
						className={`mb-20 mr-20`}
					/>

					<TextInput
						width={300}
						dataType={'text'}
						type="date"
						label="Birth date"
						// disabled={!canUserEdit}
						value={mergedUserProps.birthDate ? String(mergedUserProps.birthDate) : ''}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'birthDate',
									value: updatedValue,
								},
							})
						}}
						className={`mb-20 mr-20`}
					/>

					<PhoneInput
						width={300}
						dataType="number"
						label="Phone number"
						value={parseFloat(mergedUserProps.phone)}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'phone',
									value: updatedValue,
								},
							})
						}}
						className={`mb-20 mr-20`}
					/>

					{!canUserEdit ? null : (
						<TextInput
							width={300}
							dataType="text"
							label="Back at You User ID"
							placeholder=""
							value={mergedUserProps.bayUserId ? String(mergedUserProps.bayUserId) : ''}
							onChange={(updatedValue) => {
								userProfileDispatch({
									type: 'update-property',
									payload: {
										key: 'bayUserId',
										value: updatedValue,
									},
								})
							}}
						/>
					)}
				</div>

				<div className="col-xs-12" style={{ gridColumn: '1 / span 2' }}>
					<h4>Address</h4>
				</div>
				<div className="flex flex-wrap mb-20 mt-10 registration__field-wrapper" style={{ maxWidth: `800px` }}>
					<TextInput
						width={'100%'}
						dataType={'text'}
						label="Address 1"
						placeholder="Address"
						value={mergedUserProps.address1}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'address1',
									value: updatedValue,
								},
							})
						}}
						disabled={!canUserEdit}
					/>

					<TextInput
						width={'100%'}
						dataType={'text'}
						label="Address 2"
						placeholder="Address"
						value={mergedUserProps.address2 ?? ''}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'address2',
									value: updatedValue,
								},
							})
						}}
						disabled={!canUserEdit}
					/>

					<TextInput
						width={'100%'}
						dataType={'text'}
						label="City"
						placeholder="City"
						value={mergedUserProps.city}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'city',
									value: updatedValue,
								},
							})
						}}
						disabled={!canUserEdit}
					/>

					<Dropdown<string>
						value={mergedUserProps.mailingState ? [mergedUserProps.mailingState] : []}
						label="State"
						searchable={true}
						options={getMailingStateOptions()}
						onSelect={(selectedValues) => {
							if (selectedValues.length > 0) {
								userProfileDispatch({
									type: 'update-property',
									payload: {
										key: 'mailingState',
										value: selectedValues[0],
									},
								})
							}
						}}
						disabled={!canUserEdit}
					/>

					<TextInput
						width={'100%'}
						dataType={'text'}
						label="ZIP Code"
						placeholder="ZIP Code"
						align="left"
						value={mergedUserProps.zip}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'zip',
									value: updatedValue,
								},
							})
						}}
						disabled={!canUserEdit}
					/>
				</div>

				<div className="col-xs-12 mb-20">
					<strong className="pb-5">Bio</strong>
					<TextInput
						dataType="text"
						width="100%"
						rows={4}
						value={mergedUserProps.bio}
						onChange={(updatedValue) => {
							let adjustedCharCount = updatedValue.length
							const newLineCount = (updatedValue.match(/\n/g) || []).length
							adjustedCharCount += newLineCount
							setCharCount(adjustedCharCount)
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'bio',
									value: updatedValue,
								},
							})
						}}
						maxLength={500}
					/>
					<div className="flex flex-justifyContent-end">{charCount}/500</div>
				</div>

				<div className=" mb-50" style={{ gridColumn: '1 / span 2' }}>
					<strong>Headshot</strong>
					<ImageUploadTile
						className="bg-color__adjust-5"
						image={userPhoto ? userPhoto : ''}
						onUpload={(img) => {
							setUserPhoto(img)
							userProfileDispatch({ type: 'update-profile-photo', payload: img })
						}}
						onRemove={() => {
							setUserPhoto(null)
						}}
					/>
					{canUserEdit && props.userToEdit.hasProfilePicture && (
						<LoKationButton
							variant="contained"
							label="Download Photo"
							size={'sm'}
							onClick={() => {
								generateImageDownload(
									`${props.userToEdit.firstName}_${props.userToEdit.lastName}_profile_picture`,
								)
							}}
							className="mt-20"
						/>
					)}
				</div>

				<div className="col-xs-12" style={{ gridColumn: '1 / span 2' }}>
					<h4>Social Media</h4>
					<div>Provide links to your various social media accounts. All links are optional</div>
				</div>

				<div className="flex flex-wrap mb-20 mt-10" style={{ maxWidth: `800px` }}>
					<TextInput
						width={300}
						className="mr-20 mb-20"
						dataType={'text'}
						label='Twitter ("X") URL'
						placeholder="https://www.twitter.com/username"
						value={mergedUserProps.twitterUsername}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'twitterUsername',
									value: updatedValue,
								},
							})
						}}
					/>

					<TextInput
						width={300}
						className="mr-20 mb-20"
						dataType={'text'}
						label="LinkedIn URL"
						placeholder="https://www.linkedin.com/in/username/"
						value={mergedUserProps.linkedInUsername}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'linkedInUsername',
									value: updatedValue,
								},
							})
						}}
					/>

					<TextInput
						width={300}
						className="mr-20 mb-20"
						dataType={'text'}
						label="Facebook URL"
						placeholder="https://www.facebook.com/username"
						value={mergedUserProps.facebookUsername}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'facebookUsername',
									value: updatedValue,
								},
							})
						}}
					/>

					<TextInput
						width={300}
						className="mr-20 mb-20"
						dataType={'text'}
						label="Instagram URL"
						placeholder="https://www.instagram.com/username"
						value={mergedUserProps.instagramUsername}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'instagramUsername',
									value: updatedValue,
								},
							})
						}}
					/>

					<TextInput
						width={300}
						className="mr-20 mb-20"
						dataType={'text'}
						label="Website URL"
						placeholder="https://lokationagent.com/"
						value={mergedUserProps.websiteUrl}
						onChange={(updatedValue) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'websiteUrl',
									value: updatedValue,
								},
							})
						}}
					/>
				</div>

				<div
					className="col-xs-12 flex flex-alignItems-center border-thin border-radius__std p-20 mb-20 flex-column-sm-down"
					style={{ maxWidth: `800px` }}
				>
					<div>
						<FontAwesomeIcon icon={'file-invoice-dollar'} size={'3x'} color="#66A5B4" />
					</div>
					<div className="col-xs-12 ml-30 ml-0-sm-down mt-10-sm-down">
						<h4>Refer agents and receive a discount</h4>
						<p>
							For each agent that you refer to the LoKation Sphere, you will receive a credit to your bill
							dependent on that agent's selected plan.
						</p>
					</div>
					<LoKationButton
						variant="contained"
						label="Copy Referral Code"
						size={'sm'}
						onClick={() => {
							CopyToClipboard(
								`https://joinlokation.com/referral/?fname=${props.userToEdit.firstName}&lname=${props.userToEdit.lastName}&referral=${props.userToEdit.endUserId}`,
								'STRING',
								'Referral URL copied to your clipboard!',
							)
						}}
						className="ml-20 ml-0-sm-down"
						style={{ width: `200px` }}
					/>
				</div>

				{referrals && referrals.length > 0 && (
					<Paper
						bgColor="primary"
						padding={['all']}
						marginSize="section"
						margins={['bottom']}
						className="col-xs-12"
						style={{ maxWidth: `800px` }}
					>
						<div className="flex flex-column">
							<strong>Active Referrals</strong>
							<div className="col-12 mt-10">
								{referrals &&
									referrals.length > 0 &&
									referrals.map((referral) => (
										<div
											key={referral.endUserId}
											className="col-xs-12 bg-color__adjust-5 py-10 px-20 mb-10 flex flex-alignItems-center flex-justifyContent-spaceBetween"
											onClick={() => {
												setReferralModal(referral.endUserId)
											}}
											style={{ cursor: 'pointer' }}
										>
											<div className="flex flex-alignItems-center">
												<Avatar
													size={'md'}
													className="mr-20"
													// tooltip="Account"
													tooltipPlacement="bottom"
													id="desktop-app-header__user__btn"
													onClick={() => {
														// setReferralModal(referral.endUserId)
													}}
													imageUrl={imageUrls[referral.endUserId]}
													fallbackCharacter={referral ? referral.fullName[0] : 'X'}
												/>

												<div>{referral.fullName}</div>
											</div>
											<FontAwesomeIcon icon={'chevron-right'} />
										</div>
									))}
							</div>
						</div>
					</Paper>
				)}
				{referralModal && <UserProfileModal userId={referralModal} dismissModal={handleDismissModal} />}

				<div className="col-xs-12" style={{ gridColumn: '1 / span 2' }}>
					<h4>Specialties</h4>
					<div>Indicate your areas of focus</div>
				</div>
				<div className="mb-40 mt-10" style={{ maxWidth: `800px` }}>
					{availableSpecialties.map((thisSpecialty) => {
						const isChecked = getSelectedSpecialtyIds().includes(thisSpecialty.specialtyId)

						return (
							<Checkbox
								key={thisSpecialty.specialtyId}
								checked={isChecked}
								className="mb-10"
								onChange={(state) => {
									let updatedState = _.cloneDeep(mergedUserProps.specialties)
									if (state) {
										updatedState.push(thisSpecialty)
									} else {
										updatedState = updatedState.filter(
											(selectedSpecialty) =>
												selectedSpecialty.specialtyId !== thisSpecialty.specialtyId,
										)
									}

									userProfileDispatch({
										type: 'update-property',
										payload: {
											key: 'specialties',
											value: updatedState,
										},
									})
								}}
								disabled={!canUserEdit}
							>
								{thisSpecialty.specialtyName}
							</Checkbox>
						)
					})}
				</div>

				<div className="col-xs-12" style={{ gridColumn: '1 / span 2' }}>
					<h4>Languages</h4>
					<div>Indicate languages you're fluent in </div>
				</div>
				<div className="mb-40 mt-10" style={{ maxWidth: `800px` }}>
					{availableLanguageSpecialties.map((thisLanguageSpecialty) => {
						const isLanguageChecked = getSelectedLanguageSpecialtyIds().includes(
							thisLanguageSpecialty.languageSpecialtyId,
						)

						return (
							<Checkbox
								key={thisLanguageSpecialty.languageSpecialtyId}
								checked={isLanguageChecked}
								className="mb-10"
								onChange={(state) => {
									let updatedState = _.cloneDeep(mergedUserProps.languageSpecialties)
									if (state) {
										updatedState.push(thisLanguageSpecialty)
									} else {
										updatedState = updatedState.filter(
											(selectedSpecialty) =>
												selectedSpecialty.languageSpecialtyId !==
												thisLanguageSpecialty.languageSpecialtyId,
										)
									}

									userProfileDispatch({
										type: 'update-property',
										payload: {
											key: 'languageSpecialties',
											value: updatedState,
										},
									})
								}}
							>
								{thisLanguageSpecialty.languageName}
							</Checkbox>
						)
					})}
				</div>

				<h5>Notification Preferences</h5>
				<div className="mb-40 mt-10" style={{ maxWidth: `800px` }}>
					<div className="mb-10">
						<strong>Please select which methods you would like us to use when contacting you</strong>
					</div>

					<Checkbox
						className="mb-10"
						checked={mergedUserProps.notificationsEmail}
						onChange={(state) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'notificationsEmail',
									value: state,
								},
							})
						}}
					>
						Email
					</Checkbox>
					<Checkbox
						className="mb-10"
						checked={mergedUserProps.notificationsText}
						onChange={(state) => {
							userProfileDispatch({
								type: 'update-property',
								payload: {
									key: 'notificationsText',
									value: state,
								},
							})
						}}
					>
						Text message
					</Checkbox>
					<Checkbox
						className="mb-10"
						checked={!mergedUserProps.notificationsEmail && !mergedUserProps.notificationsText}
						onChange={(state) => {
							if (state) {
								userProfileDispatch({
									type: 'update-property',
									payload: {
										key: 'notificationsEmail',
										value: false,
									},
								})
								userProfileDispatch({
									type: 'update-property',
									payload: {
										key: 'notificationsText',
										value: false,
									},
								})
							}
						}}
					>
						Opt out of receiving notifications
					</Checkbox>
				</div>
			</Paper>
		</>
	)
}

export default PersonalInformation
